import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"


import { Section, Container } from "../../anti/grid/grid"
import { ButtonGroup, Button } from "../../anti/buttons/buttons"
import { Stats } from "../../anti/stats/stats"

export const AcfColWTextStatsBlock = graphql`
  fragment AcfColWTextStatsBlock on WordPress_AcfColTextWStatsBlock {
    name
    attributes {
      className
    }
    colTextWStats {
      bgColor
      title
      text
      buttons {
        text
        link {
          target
          url
        }
      }
      stats {
        label
        value
      }
    }
  }
`

export const AcfColTextWStats = ({ data }) => {
  const colTextWStats = data.colTextWStats
  return (
    <Section
      className={`${colTextWStats.bgColor} ${data.attributes.className} pb-sm-down-0`}
    >
      <Container>
        <div className="row row-5">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-start pb-sm-down-4">
            <h2 className="h4">
              <ReactMarkdown
                source={colTextWStats.title}
                escapeHtml={false}
              />
            </h2>
            <p className="lead">
              <ReactMarkdown
                source={colTextWStats.text}
                escapeHtml={false}
              />
            </p>
            {colTextWStats.buttons && (
              <ButtonGroup>
                {colTextWStats.buttons.map((item, i) => {
                  return (
                    <Button
                      variant="link"
                      link={item.link && item.link.url}
                      target={item.link && item.link.target}
                      key={i}
                    >
                      {item.text}
                    </Button>
                  )
                })}
              </ButtonGroup>
            )}
          </div>
          <div className="col-md-6">
            <Stats stats={colTextWStats.stats} />
          </div>
        </div>
      </Container>
    </Section>
  )
}

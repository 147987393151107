import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { Section, Container } from "../../anti/grid/grid"
import { ButtonGroup, Button } from "../../anti/buttons/buttons"
import { Stats } from "../../anti/stats/stats"

export const acfColTextWStatsStaticBlock = graphql`
  fragment AcfColTextWStatsStaticBlock on WordPress_AcfColTextWStatsStaticBlock {
    attributes {
      className
    }
    colTextWStats {
      bgColor
      title
      text
      buttons {
        text
        link {
          target
          url
        }
      }
    }
  }
`

export const AcfColTextWStatsStatic = ({ data }) => {
  const colTextWStats = data ? data.colTextWStats : false
  return (
    <Section className={`${colTextWStats.bgColor}`}>
      <Container>
        <div className="row row-5">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-start">
            <h2 className="h4">
              <ReactMarkdown
                source={colTextWStats.title}
                escapeHtml={false}
              />
            </h2>
            <p className="lead">
              <ReactMarkdown
                source={colTextWStats.text}
                escapeHtml={false}
              />
            </p>
            {colTextWStats.buttons && (
              <ButtonGroup>
                {colTextWStats.buttons.map((item, i) => {
                  return (
                    <Button
                      variant="link"
                      link={item.link && item.link.url}
                      target={item.link && item.link.target}
                      key={i}
                    >
                      {item.text}
                    </Button>
                  )
                })}
              </ButtonGroup>
            )}
          </div>
          <div className="col-md-6">
            <Stats
              stats={[
                { value: "290", label: "Best Property Award" },
                { value: "30", label: "APAC Property Awards" },
                {
                  value: "219",
                  label: "Best Developer Project Awards",
                },
                { value: "30", label: "Best CSR Awards" },
              ]}
            />
          </div>
        </div>
      </Container>
    </Section>
  )
}

import React, { useState, useContext } from "react"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import ReCAPTCHA from "react-google-recaptcha"
import ReactSelect from "react-select"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"
import moment from "moment"
import { useStaticQuery, graphql } from "gatsby"

import { LangContext } from "../context/LangContext"


import { Button } from "../components/anti/buttons/buttons"
import { Section, Container } from "../components/anti/grid/grid"
import { Card } from "../components/anti/card/card"
import { getDate } from "../utils/get-date"
import { Select } from "../components/anti/form/form"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const button = (submitStatus, loading, onClick, lang) => {
  if (submitStatus === "success") {
    return (
      <>
        <button
          disabled
          className="btn btn-success btn-block mt-4"
          type="submit"
        >
          <i className="fas fa-check"></i>
        </button>
        <div className="d-flex justify-content-center pt-3">
          <p>
            {lang == "EN" ? "Your message has been delivered" : "Pesan Kamu telah terkirim"}
          </p>
        </div>
      </>
    )
  } else if (submitStatus === "error") {
    return (
      <>
        <button
          disabled={loading}
          className="btn btn-secondary btn-block mt-4"
          type="submit"
        >
          {lang == "EN" ? "Send" : "Kirim"}
        </button>
        <div className="d-flex justify-content-center pt-3">
          <p>
            {lang == "EN" ? "Something went wrong" : "Ada yang salah"} 
          </p>
        </div>
      </>
    )
  } else {
    return (
      <button
        disabled={loading}
        className="btn btn-secondary btn-block mt-4"
        type="submit"
        onClick={onClick}
      >
        {loading ? <i className="fa fa-spinner fa-spin"></i> : `${lang == "EN" ? "Send" : "Kirim"}`}
      </button>
    )
  }
}

export const ContactUsForm = () => {
  const CONTACT_SUBMIT = gql`
    mutation MyMutation(
      $fullname: String!
      $email: String!
      $isBsdResidence: String!
      $message: String!
      $phonenumber: String!
      $subjectTo: String!
    ) {
      submit2FormContactUs(
        input: {
          clientMutationId: ""
          email: $email
          fullName: $fullname
          isBsdResidence: $isBsdResidence
          message: $message
          phoneNumber: $phonenumber
          subjectTo: $subjectTo
        }
      ) {
        message
        status
      }
    }
  `
  const [submitData, { loading }] = useMutation(CONTACT_SUBMIT)
  const [formValues, setFormValues] = useState({
    email: "",
    to: "sales",
    name: "",
    bsdResident: "no",
    phonenumber: "",
    message: "",
    recaptcha: "",
  })
  const [status, setStatus] = useState()

  const FormContact = ({ touched, errors }) => {
    const { lang, setLang } = useContext(LangContext)
    return (
      <Section>
        <Container>
          <div className="row">
            <div className="col-md-7 mx-auto">
              <Form>
                <div className="form-row">
                  <div className="col-md-6 col-12 mb-3">
                    <label>{lang == "EN" ? "To" : "Kepada"}</label>
                    <ReactSelect
                      className="form-select w-100"
                      classNamePrefix="select"
                      options={[
                        { value: "sales", label: "Sales" },
                        { value: "cs", label: "Customer Service" },
                      ]}
                      defaultValue={{ value: "sales", label: "Sales" }}
                    />
                  </div>
                  <div className="col-md-6 col-12 pl-md-5">
                    <label>{lang == "EN" ? "Are you a BSD City Resident" : "Apakah kamu penduduk BSD City?"}</label>
                    <Field
                      name="bsdResident"
                      render={({ field }) => {
                        return (
                          <div className="mb-3 mb-lg-4">
                            <div className="row">
                              <div className="col-6">
                                <label className="radio-wrapper mr-md-6">
                                  {lang == "EN" ? "Yes" : "Iya"}
                                  <input
                                    {...field}
                                    id="contact-us"
                                    type="radio"
                                    checked={field.value === "yes"}
                                    name="bsdResident"
                                    value="yes"
                                  />
                                  <span className="radio-button" />
                                </label>
                              </div>
                              <div className="col-6">
                                <label className="radio-wrapper">
                                {lang == "EN" ? "No" : "Tidak"}
                                  <input
                                    {...field}
                                    id="contact-us"
                                    type="radio"
                                    checked={field.value === "no"}
                                    name="bsdResident"
                                    value="no"
                                  />
                                  <span className="radio-button" />
                                </label>
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label>{lang == "EN" ? "Name" : "Nama"}</label>
                  <Field
                    className="form-control"
                    name="name"
                    placeholder={lang == "EN" ? "Enter Your Name" : "Masukkan Nama Kamu"}
                    type="text"
                  />
                  {touched.name && errors.name && (
                    <div className="invalid-value d-block">{errors.name}</div>
                  )}
                </div>
                <div className="form-row">
                  <div className="col-md-6 col-12 mb-3">
                    <label>Email</label>
                    <Field
                      className="form-control"
                      name="email"
                      placeholder={lang == "EN" ? "E.g. name@email.com" : "Contoh. nama@email.com"}
                      type="email"
                    />
                    {touched.email && errors.email && (
                      <div className="invalid-value d-block">
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <label>{lang == "EN" ? "Phone Number" : "Nomor Telepon"}</label>
                    <Field
                      className="form-control"
                      name="phonenumber"
                      placeholder={lang == "EN" ? "Input Phone Number" : "Masukkan Nomor Telepon"}
                      type="number"
                    />
                    {touched.phonenumber && errors.phonenumber && (
                      <div className="invalid-value d-block">
                        {errors.phonenumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>{lang == "EN" ? "Message" : "Pesan"}</label>
                  <Field
                    className="form-control"
                    name="message"
                    placeholder={lang == "EN" ? "Enter Your Message" : "Masukkan Pesan Kamu"}
                    as="textarea"
                    rows={4}
                  />
                  {touched.message && errors.message && (
                    <div className="invalid-value d-block">
                      {errors.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <Field name="recaptcha">
                    {({ field, form }) => (
                      <ReCAPTCHA
                        sitekey={process.env.RECAPTCHA_SITE_KEY}
                        onChange={value => {
                          form.setFieldValue("recaptcha", value)
                        }}
                      />
                    )}
                  </Field>
                  {touched.recaptcha && errors.recaptcha && (
                    <div className="invalid-value d-block">
                      {errors.recaptcha}
                    </div>
                  )}
                </div>
                {button(status, loading, lang)}
              </Form>
            </div>
          </div>
        </Container>
      </Section>
    )
  }

  const ContactUs = withFormik({
    mapPropsToValues({
      email,
      to,
      name,
      bsdResident,
      phonenumber,
      message,
      recaptcha,
    }) {
      return {
        email: email || formValues.email,
        to: to || formValues.to,
        name: name || formValues.name,
        bsdResident: bsdResident || formValues.bsdResident,
        phonenumber: phonenumber || formValues.phonenumber,
        message: message || formValues.message,
        recaptcha: recaptcha || formValues.recaptcha,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required!"),
      phonenumber: Yup.string().required("Phone Number is required!"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required!"),
      message: Yup.string().required("Message is required!"),
      recaptcha: Yup.string().required(
        "Please verify that you are not a robot"
      ),
    }),
    handleSubmit(values) {
      //prettier-ignore
      const { name, to, email, bsdResident, phonenumber, message, recaptcha } = values
      //prettier-ignore
      setFormValues({ email, to, name, bsdResident, phonenumber, message,recaptcha })

      setTimeout(() => {
        submitData({
          variables: {
            email,
            fullname: name,
            isBsdResidence: bsdResident,
            message,
            phonenumber,
            subjectTo: to,
          },
        })
          .then(res => {
            if (res.data.submit2FormContactUs.status === "success") {
              setStatus("success")
            }
          })
          .catch(err => err)
      }, 500)
    },
  })(FormContact)

  return <ContactUs />
}

export const InvestorsRelationsForm = () => {
  const { lang, setLang } = useContext(LangContext)
  const INVESTORS_SUBMIT = gql`
    mutation MyMutation(
      $address: String
      $city: String
      $companyName: String!
      $country: String
      $email: String!
      $firstName: String!
      $lastName: String!
      $message: String!
      $phoneNumber: String!
      $salutation: String!
      $subject: String!
    ) {
      submit2FormInvestorRelation(
        input: {
          clientMutationId: ""
          address: $address
          city: $city
          companyName: $companyName
          country: $country
          email: $email
          firstName: $firstName
          lastName: $lastName
          message: $message
          phoneNumber: $phoneNumber
          salutation: $salutation
          subject: $subject
        }
      ) {
        status
      }
    }
  `
  const [submitData, { loading }] = useMutation(INVESTORS_SUBMIT)
  const [formValues, setFormValues] = useState({
    address: "",
    city: "",
    companyName: "",
    country: "",
    email: "",
    firstName: "",
    lastName: "",
    message: "",
    phoneNumber: "",
    salutation: "mr",
    subject: "",
  })
  const [status, setStatus] = useState()

  const FormInvestorsRelations = props => {
    const { touched, errors } = props
    return (
      <Section className="pt-2">
        <Container>
          <div className="row">
            <div className="col-md-7 mx-auto">
              <Form>
                <div className="form-group">
                  <label>{lang == "EN" ? "Salutations" : "Sapaan"}</label>
                  <div
                    className="row"
                    role="group"
                    aria-labelledby="my-radio-group"
                  >
                    <Field
                      name="salutation"
                      render={({ field }) => {
                        return (
                          <>
                            <div className="col-4">
                              <label className="radio-wrapper mr-md-6">
                                {lang == "EN" ? "Mr" : "Tuan"}
                                <input
                                  {...field}
                                  id="investors-relations"
                                  type="radio"
                                  checked={field.value === "mr"}
                                  name="salutation"
                                  value="mr"
                                />
                                <span className="radio-button" />
                              </label>
                            </div>
                            <div className="col-4">
                              <label className="radio-wrapper">
                              {lang == "EN" ? "Mrs" : "Nyonya"}
                                <input
                                  {...field}
                                  id="investors-relations"
                                  type="radio"
                                  checked={field.value === "mrs"}
                                  name="salutation"
                                  value="mrs"
                                />
                                <span className="radio-button" />
                              </label>
                            </div>
                            <div className="col-4">
                              <label className="radio-wrapper">
                              {lang == "EN" ? "Ms" : "Nona"}
                                <input
                                  {...field}
                                  id="investors-relations"
                                  type="radio"
                                  checked={field.value === "ms"}
                                  name="salutation"
                                  value="ms"
                                />
                                <span className="radio-button" />
                              </label>
                            </div>
                          </>
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-6 col-12 mb-3">
                    <label>{lang == "EN" ? "First Name" : "Nama Pertama"}</label>
                    <Field
                      className="form-control"
                      name="firstName"
                      placeholder={lang == "EN" ? "Enter your first name" : "Masukkan nama depan anda"}
                      type="text"
                    />
                    {touched.firstName && errors.firstName && (
                      <div className="invalid-value d-block">
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <label>{lang == "EN" ? "Last Name" : "Nama Belakang"}</label>
                    <Field
                      className="form-control"
                      name="lastName"
                      placeholder={lang == "EN" ? "Enter your last name" : "Masukkan nama belakang anda"}
                      type="text"
                    />
                    {touched.lastName && errors.lastName && (
                      <div className="invalid-value d-block">
                        {errors.lastName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>{lang == "EN" ? "Company Name" : "Nama Perusahaan"}</label>
                  <Field
                    className="form-control"
                    name="companyName"
                    placeholder="Eg. PT Bumi Serpong Damai Tbk"
                    type="text"
                  />
                  {touched.companyName && errors.companyName && (
                    <div className="invalid-value d-block">
                      {errors.companyName}
                    </div>
                  )}
                </div>
                <div className="form-row">
                  <div className="col-md-6 col-12 mb-3">
                    <label>{lang == "EN" ? "Phone Number" : "Telepon"}</label>
                    <Field
                      className="form-control"
                      name="phoneNumber"
                      placeholder={lang == "EN" ? "Input Phone Number" : "Masukkan Nomor Telepon"}
                      type="number"
                    />
                    {touched.phoneNumber && errors.phoneNumber && (
                      <div className="invalid-value d-block">
                        {errors.phoneNumber}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <label>Email</label>
                    <Field
                      className="form-control"
                      name="email"
                      placeholder="Eg. name@email.com"
                      type="email"
                    />
                    {touched.email && errors.email && (
                      <div className="invalid-value d-block">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>{lang == "EN" ? "Address (optional)" : "Alamat (tidak wajib)"}</label>
                  <Field
                    className="form-control"
                    name="address"
                    placeholder={lang == "EN" ? "Enter your address" : "Masukkan alamat anda"}
                    type="text"
                  />
                  {touched.address && errors.address && (
                    <div className="invalid-value d-block">
                      {errors.address}
                    </div>
                  )}
                </div>
                <div className="form-row">
                  <div className="col-md-6 col-12 mb-3">
                    <label>{lang == "EN" ? "City (optional)" : "Kota (tidak wajib)"}</label>
                    <Field
                      className="form-control"
                      name="city"
                      placeholder={lang == "EN" ? "Enter city" : "Masukkan Kota"}
                      type="text"
                    />
                    {touched.city && errors.city && (
                      <div className="invalid-value d-block">{errors.city}</div>
                    )}
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <label>{lang == "EN" ? "Country (optional)" : "Negara (tidak wajib)"}</label>
                    <Field
                      className="form-control"
                      name="country"
                      placeholder={lang == "EN" ? "Enter country" : "Masukkan Negara"}
                      type="text"
                    />
                    {touched.city && errors.city && (
                      <div className="invalid-value d-block">{errors.city}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>{lang == "EN" ? "Subject" : "Subyek"}</label>
                  <Field
                    className="form-control"
                    name="subject"
                    placeholder={lang == "EN" ? "Enter subject" : "Masukkan Subyek"}
                    type="text"
                  />
                  {touched.subject && errors.subject && (
                    <div className="invalid-value d-block">
                      {errors.subject}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label>{lang == "EN" ? "Message" : "Pesan"}</label>
                  <Field
                    className="form-control"
                    name="message"
                    placeholder={lang == "EN" ? "Enter Your Message" : "Masukkan pesan Anda"}
                    as="textarea"
                    rows={4}
                  />
                  {touched.message && errors.message && (
                    <div className="invalid-value d-block">
                      {errors.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <Field name="recaptcha">
                    {({ field, form }) => (
                      <ReCAPTCHA
                        sitekey={process.env.RECAPTCHA_SITE_KEY}
                        onChange={value => {
                          form.setFieldValue("recaptcha", value)
                        }}
                      />
                    )}
                  </Field>
                  {touched.recaptcha && errors.recaptcha && (
                    <div className="invalid-value d-block">
                      {errors.recaptcha}
                    </div>
                  )}
                </div>
                {button(status, loading)}
              </Form>
            </div>
          </div>
        </Container>
      </Section>
    )
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const MyFormInvestorsRelations = withFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required!"),
      firstName: Yup.string().required("First Name is required!"),
      lastName: Yup.string().required("Last Name is required!"),
      companyName: Yup.string().required("Company Name is required!"),
      subject: Yup.string().required("Subject is required!"),
      message: Yup.string().required("Message is required!"),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Too Short")
        .max(12, "Too Long")
        .required("Phone Number is required!"),
      recaptcha: Yup.string().required(
        "Please verify that you are not a robot"
      ),
    }),
    mapPropsToValues({
      email,
      firstName,
      lastName,
      companyName,
      phoneNumber,
      message,
      subject,
      salutation,
      address,
      recaptcha,
      city,
      country,
    }) {
      return {
        email: email || formValues.email,
        lastName: lastName || formValues.lastName,
        firstName: firstName || formValues.firstName,
        companyName: companyName || formValues.companyName,
        phoneNumber: phoneNumber || formValues.phoneNumber,
        address: address || formValues.address,
        subject: subject || formValues.subject,
        message: message || formValues.message,
        salutation: salutation || formValues.salutation,
        recaptcha: recaptcha || formValues.recaptcha,
        city: city || formValues.city,
        country: country || formValues.country,
      }
    },
    handleSubmit(values) {
      //prettier-ignore
      const { address, city, companyName, country, email, firstName, lastName, message, phoneNumber, salutation, subject } = values
      //prettier-ignore
      setFormValues({ address, city, companyName, country, email, firstName, lastName, message, phoneNumber, salutation, subject })

      setTimeout(() => {
        submitData({
          variables: {
            address,
            city,
            companyName,
            country,
            email,
            firstName,
            lastName,
            message,
            phoneNumber,
            salutation,
            subject,
          },
        })
          .then(res => {
            if (res.data.submit2FormInvestorRelation.status === "success") {
              setStatus("success")
            }
          })
          .catch(err => err)
      }, 500)
    },
  })(FormInvestorsRelations)

  return <MyFormInvestorsRelations />
}

export const IndeksForm = () => {
  const { lang, setLang } = useContext(LangContext)
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        categories(first: 100) {
          nodes {
            slug
            name
            posts(first: 1000) {
              nodes {
                slug
                title
                excerpt
                dateGmt
                featuredImage {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `)

  const masterPost = []
  let categorySelectedList = []

  const categoriesList = staticData?.wordPress?.categories

  categoriesList?.nodes?.forEach(category => {
    categorySelectedList.push({
      value: category?.slug,
      label: category?.name,
    })
    category?.posts?.nodes?.forEach(item => {
      masterPost.push({...item, categoryName: category?.name, categorySlug: category?.slug})
    })
  })

  const now = moment().format('YYYY-MM-DD')
  const [formValues, setFormValues] = useState({
    category: "all",
    dateTime: now
  })

  const offset = 0
  const limit = 12
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(limit)
  const [allPosts, setAllPosts] = useState(masterPost)
  const [filteredPosts, setFilteredPosts] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [nothingPostMsg, setNothingPostMsg] = useState("")

  const removeDuplicateCategories = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  const allCategoryLabel = { value: "all", label: "Salect news category" }

  let categorySelected = removeDuplicateCategories(categorySelectedList, 'value')

  categorySelected.splice(0, 0, allCategoryLabel)

  const handleDateTime = (e) => {
    setFormValues({...formValues, dateTime: e.target.value})
  }

  const handleCategoryChange = (e) => { 
    setSelectedCategory(e)
    setFormValues({...formValues, category: e.value})
  }

  const handleLoadMore = () => {
    const nextPage = page + 1
    const nextPerPage = nextPage * limit
    console.log({nextPage, nextPerPage})
    setPage(nextPage)
    setPerPage(nextPerPage)
  }

  const FormIndeks = () => {
    return (
      <Section>
        <Container>
          <Form>
            <div className="row">
              <div className="col-md-6">
                <label>{lang == "EN" ? "Category" : "Kategori"}</label>
                <Select
                  name="category"
                  className="form-select w-100"
                  classNamePrefix="select"
                  options={categorySelected}
                  value={selectedCategory}
                  callback={handleCategoryChange}
                  placeholder={lang == "EN" ? "Salect news category" : "Semua berita kategori"}
                />
              </div>
              <div className="col-md-6">
                <label>{lang == "EN" ? "Date" : "Tanggal"}</label>
                <Field
                  className="form-control"
                  name="dateTime"
                  type="date"
                  onChange={e => handleDateTime(e)}
                />
              </div>
            </div>
            <button
              className="btn btn-secondary btn-block mt-4"
              type="submit"
            >
              {lang == "EN" ? "Submit" : "Kirim"}
            </button>
          </Form>
        </Container>
      </Section>
    )
  }

  const Indeks = withFormik({
    mapPropsToValues({
      category,
      dateTime,
    }) {
      return {
        category: category || formValues.category,
        dateTime: dateTime || formValues.dateTime,
      }
    },
    validationSchema: Yup.object().shape({
      category: Yup.string().required("Choose one of category"),
      dateTime: Yup.string().required("Please select date time indeks"),
    }),
    handleSubmit(values) {
      try {
        const { category, dateTime } = values
        setFormValues({ category, dateTime })
        let posts
        let msg = ""
    
        console.log("LOGvalues ===> ", values)
    
        if (values?.category === "all") {
          if (values?.dateTime === now) {
            posts = masterPost;
          } else {
            posts = masterPost.filter(item => moment(item?.dateGmt).format('YYYY-MM-DD') === values?.dateTime);
          }
        } else {
          posts = masterPost.filter(item => item?.categorySlug === values?.category && moment(item?.dateGmt).format('YYYY-MM-DD') === values?.dateTime);
        }

        setFilteredPosts(posts)

        if (posts.length > 0) {
          msg = ""
        } else {
          if (lang === "EN") {
            msg = "Data not available"
          } else {
            msg = "Data tidak ditemukan"
          }
        }

        setNothingPostMsg(msg)

      } catch (error) {
        console.log(error);
      }
    },
  })(FormIndeks)
  return (
    <>
      <Indeks />
      {filteredPosts && filteredPosts.length > 0 ? (
        <Container>
          <div className="row col-md-12 m-0 pl-5 pr-5">
            {filteredPosts.slice(offset, perPage).map((item, i) =>{
              return(
                <Card
                  key={i}
                  variant="basic"
                  colLeft="col-md-5"
                  colRight="col-md-7"
                  label={`${item?.categoryName} - ${getDate(item?.dateGmt, lang, { time: false })}`}
                  title={item?.title}
                  text={item?.excerpt}
                  img={item?.featuredImage?.sourceUrl}
                  imgHeight="h-ratio-3-2"
                  link={`/news-events/${item?.slug}/`}
                  className="card-post w-100"
                />
              )
            })}
          </div>
          <div className="col-md-12 pb-5 text-center">
            {filteredPosts.length > perPage ? (
                <button 
                  className="btn btn-primary w-100 w-md-25"
                  onClick={handleLoadMore}
                >
                  Load More
                </button>
            ) : ""}
          </div>
        </Container>
      ):(
        <div className="nothing-post text-center font-weight-bold pb-5">
          <span>{nothingPostMsg}</span>
        </div>
      )}
    </>
  )
}

import React from "react"
import { Card } from "../../anti/card/card"
import { Section, Container } from "../../anti/grid/grid"
import ReactMarkdown from "react-markdown"

export const AcfListImage = ({ data }) => {
  const listImage = data ? data : false
  return (
    <Section
      className={`${listImage.acfListImage.bgColor} ${
        listImage.attributes && listImage.attributes.className
          ? listImage.attributes.className
          : ""
      }`}
    >
      <Container className="mw-lg text-md-center">
        <h2 className="h2">
          <ReactMarkdown source={listImage.acfListImage.title} escapeHtml={false} />
        </h2>
        <p>
          <ReactMarkdown source={listImage.acfListImage.text} escapeHtml={false} />
        </p>
        <div className="row">
          {listImage &&
            listImage.acfListImage.list.map((item, i) => {
              return (
                <div className="col-md-4 col-6 mb-5">
                  <Card
                    variant="boxless"
                    title={item.title}
                    className="text-left text-limit"
                    titleClassName="h6"
                    text={item.text}
                    imgLabel={item.label}
                    img={item.img.sourceUrl}
                    imgHeight="h-ratio-1-1"
                    key={i}
                    imgLabelOverlay={true}
                  />
                </div>
              )
            })}
        </div>
      </Container>
    </Section>
  )
}

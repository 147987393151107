import React from "react"
import PropTypes from "prop-types"

import { Section, Container } from "../grid/grid"

export const StockSection = () => {
  return (
    <Section className="stock">
      <Container>
        {/* Desktop only */}
        <div className="d-none d-md-block">
          <div className="row">
            <div className="col-md">
              <div className="stock-num">BSDE.JK</div>
              <div className="d-md-flex d-none justify-content-between">
                <a
                  href="https://finance.yahoo.com/quote/BSDE.JK/"
                  className="btn btn-link font-weight-bold text-left"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stock Performance <i className="fal fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-md text-md-right">
              <div className="stock-num">7.800</div>
              <div className="stock-label">IDR</div>
            </div>
            <div className="col-md text-md-right">
              <div className="stock-num">83</div>
              <div className="stock-label">Prev</div>
            </div>
            <div className="col-md text-md-right">
              <div className="stock-num">2.44%</div>
              <div className="stock-label">Change</div>
            </div>
            <div className="col-md text-md-right">
              <div className="stock-num">
                <i className="fa fa-caret-up" />
                9.99
              </div>
              <div className="stock-label">As of 17/01/2020</div>
            </div>
          </div>
        </div>
        {/* Mobile only */}
        <div className="d-md-none px-3">
          <div className="row">
            <div className="col">
              <div className="stock-label px-0">BSDE.JK</div>
              <div className="d-flex align-items-center">
                <div className="stock-num">7.800</div>
                <div className="stock-label">IDR</div>
              </div>
            </div>
            <div className="col text-right">
              <div className="stock-num">
                <i className="fa fa-caret-up" />
                9.99
              </div>
              <div className="stock-label">As of 17/01/2020</div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export const StockSectionV2 = ({ stockPrice, lang }) => {
  const bsde =
    stockPrice &&
    stockPrice.bsde !== 0 &&
    Object.keys(stockPrice.bsde).map(key => [key, stockPrice.bsde[key]])
  const duti =
    stockPrice &&
    stockPrice.duti !== 0 &&
    Object.keys(stockPrice.duti).map(key => [key, stockPrice.duti[key]])
  const a26 =
    stockPrice &&
    stockPrice.a26 !== 0 &&
    Object.keys(stockPrice.a26).map(key => [key, stockPrice.a26[key]])

  const bsdeData =
    stockPrice &&
    stockPrice.bsde !== 0 &&
    Object.keys(stockPrice.bsde.body)

  const dutiData =
    stockPrice &&
    stockPrice.duti !== 0 &&
    Object.keys(stockPrice.duti.body)

  const a26Data =
    stockPrice &&
    stockPrice.a26 !== 0 &&
    Object.keys(stockPrice.a26.body)

  let bsdeLatestTimestamp
  let dutiLatestTimestamp
  let a26LatestTimestamp
  let bsdeStock
  let dutiStock
  let a26Stock

  if (Array.isArray(bsdeData)) {
    bsdeData.sort((a, b) => b - a)
    bsdeLatestTimestamp = bsdeData[0]
    bsdeStock = stockPrice.bsde.body[bsdeLatestTimestamp]
  }

  if (Array.isArray(dutiData)) {
    dutiData.sort((a, b) => b - a)
    dutiLatestTimestamp = dutiData[0]
    dutiStock = stockPrice.duti.body[dutiLatestTimestamp]
  }

  if (Array.isArray(a26Data)) {
    a26Data.sort((a, b) => b - a)
    a26LatestTimestamp = a26Data[0]
    a26Stock = stockPrice.a26.body[a26LatestTimestamp]
  }

  const Indicator = ({ stock }) => {
    if (stock) {
      if (stock?.open > stock?.close) {
        return <i className="fa fa-caret-down" />
      } else if (stock?.open < stock?.close) {
        return <i className="fa fa-caret-up" />
      } else {
        return <div></div>
      }
    } else {
      return <div></div>
    }
  }

  // const stocks = stockPrice.data

  const changeBSDE =
    bsdeStock &&
    ((bsdeStock?.close - bsdeStock?.open) /
      bsdeStock?.open) *
      100
  const changeDUTI =
    dutiStock &&
    ((dutiStock?.close - dutiStock?.open) /
      dutiStock?.open) *
      100
  const changeA26 =
    a26Stock &&
    ((a26Stock?.close - a26Stock?.open) /
      a26Stock?.open) *
      100

  return (
    <Section className="stock">
      <Container>
        {/* Desktop only */}
        <div className="mb-3">
          <div className="stock-num">
            {lang == "EN" ? "Sinar Mas Land Stock Performance" : "Kinerja Saham Sinar Mas Land"}
          </div>
          <div className="stock-label pl-0 d-md-none">
            {lang == "EN" ? "Updated" : "Diperbarui"} {bsdeStock?.date}
          </div>
          <div className="d-md-flex d-none justify-content-between">
            <a
              href="https://finance.yahoo.com/quote/BSDE.JK/"
              className="btn btn-link font-weight-bold text-left"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang == "EN" ? "Stock Performance" : "Kinerja Saham"} <i className="fal fa-angle-right"></i>
            </a>
            <div className="stock-label mt-3">
            {lang == "EN" ? "Updated" : "Diperbarui"} {bsdeStock?.date}
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="row">
          <div className="col-md-4">
            <div className="stock-info">
              <div className="stock-heading d-flex align-items-center justify-content-between py-md-0">
                <div className="stock-num d-none d-md-block">BSDE</div>
                <div className="mobile-stock-section d-md-none">
                  <div className="stock-label pl-0">BSDE</div>
                  <div className="stock-num">
                    {bsdeStock?.close}
                    <span className="stock-label">IDR</span>
                  </div>
                </div>
                <div className="stock-num">
                  {bsdeStock && (
                    <Indicator stock={bsdeStock} />
                  )}
                  {bsdeStock &&
                    Math.abs(
                      bsdeStock?.close -
                        bsdeStock?.open
                    ).toFixed(2)}
                </div>
              </div>
              <div className="d-none d-md-block">
                <div className="stock-wrapper py-3">
                  <div className="stock-num font-weight-light">
                    {bsdeStock?.close}
                    <span className="stock-label">IDR</span>
                  </div>
                  <div className="stock-num font-weight-light">
                    {bsdeStock?.open}
                    <span className="stock-label">PREV</span>
                  </div>
                  <div className="stock-num font-weight-light">
                    {bsdeStock && `${changeBSDE.toFixed(1)}%`}
                    <span className="stock-label">CHANGE</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="stock-info">
              <div className="stock-heading d-flex align-items-center justify-content-between py-md-0">
                <div className="stock-num d-none d-md-block">DUTI</div>
                <div className="mobile-stock-section d-md-none">
                  <div className="stock-label pl-0">DUTI</div>
                  <div className="stock-num">
                    {dutiStock?.close}
                    <span className="stock-label">IDR</span>
                  </div>
                </div>
                <div className="stock-num">
                  {dutiStock && (
                    <Indicator stock={dutiStock} />
                  )}
                  {dutiStock &&
                    Math.abs(
                      dutiStock?.close -
                        dutiStock?.open
                    )}
                </div>
              </div>
              <div className="d-none d-md-block">
                <div className="stock-wrapper py-3">
                  <div className="stock-num font-weight-light">
                    {dutiStock?.close}
                    <span className="stock-label">IDR</span>
                  </div>
                  <div className="stock-num font-weight-light">
                    {dutiStock?.open}
                    <span className="stock-label">PREV</span>
                  </div>
                  <div className="stock-num font-weight-light">
                    {dutiStock && `${changeDUTI.toFixed(3)}%`}
                    <span className="stock-label">CHANGE</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="stock-info">
              <div className="stock-heading d-flex align-items-center justify-content-between py-md-0">
                <div className="stock-num d-none d-md-block">A26</div>
                <div className="mobile-stock-section d-md-none">
                  <div className="stock-label pl-0">A26</div>
                  <div className="stock-num">
                    {a26Stock?.close}
                    <span className="stock-label">SGD</span>
                  </div>
                </div>
                <div className="stock-num">
                  {a26Stock && <Indicator stock={a26Stock} />}
                  {a26Stock &&
                    Math.abs(
                      a26Stock?.close -
                        a26Stock?.open
                    ).toFixed(3)}
                </div>
              </div>
              <div className="d-none d-md-block">
                <div className="stock-wrapper py-3">
                  <div className="stock-num font-weight-light">
                    {a26Stock?.close}
                    <span className="stock-label">SGD</span>
                  </div>
                  <div className="stock-num font-weight-light">
                    {a26Stock?.open}
                    <span className="stock-label">PREV</span>
                  </div>
                  <div className="stock-num font-weight-light">
                    {a26Stock && `${changeA26.toFixed(3)}%`}
                    <span className="stock-label">CHANGE</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-md-none mt-3 mt-md-4">
          <a
            target="_blank"
            href="https://finance.yahoo.com/quote/BSDE.JK?ltr=1"
            rel="noopener noreferrer"
            className="btn btn-primary d-block font-weight-bold"
          >
            {lang == "EN" ? "See Stock Performance" : "Lihat Kinerja Saham"}
          </a>
        </div>
      </Container>
    </Section>
  )
}

StockSectionV2.propTypes = {
  stockPrice: PropTypes.string,
  stockUpdate: PropTypes.string,
  children: PropTypes.any,
}

// StockSection.propTypes = {
//   stockPrice: PropTypes.string,
//   stockUpdate: PropTypes.string,
//   children: PropTypes.any,
// }

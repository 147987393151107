import React from "react"
import PropTypes from "prop-types"

export const Stats = ({ stats }) => {
  return (
    <div className="stats">
      <div className="row row-3">
        {stats.map((stat, i) => {
          return (
            <div className="col-6">
              <div className="stat-item center-down-sm" key={i}>
                <span className="stat-number">{stat.value}</span>
                <span className="stat-label">{stat.label}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

Stats.propTypes = {
  values: PropTypes.any, // Set stats value
}

import React from "react"
import { graphql } from "gatsby"

import { Section } from "../../anti/grid/grid"
import { Map, GoogleMapsEmbed } from "../../anti/map/map"
import { Stats } from "../../anti/stats/stats"

export const AcfExperimentBlock = graphql`
  fragment AcfExperimentBlock on WordPress_AcfExperimentBlock {
    name
    attributes {
      className
    }
    mapWStats {
      map {
        long
        lat
      }
      stats {
        label
        value
      }
    }
  }
`

export const AcfExperiment = ({ data, embedUrl }) => {
  const mapWStats = data.mapWStats

  return (
    <Section
      className={`py-0 ${mapWStats.bgColor} ${data.attributes.className}`}
    >
      <div className="row row-0 experiment-wrapper">
        <div className="col-md-8">
          <GoogleMapsEmbed embedUrl={embedUrl} />
          {/* <Map
            long={parseFloat(mapWStats.map.long)}
            lat={parseFloat(mapWStats.map.lat)}
            zoom={16}
          /> */}
        </div>
        <div className="col-md-4">
          <Stats stats={mapWStats.stats} />
        </div>
      </div>
    </Section>
  )
}

import React from "react"
import { graphql } from "gatsby"

import { Section, Container } from "../../anti/grid/grid"
import { CardSliderDuo } from "../../anti/card-slider-duo/card-slider-duo"

export const DualCardSliderBlock = graphql`
  fragment AcfDualCardSliderBlock on WordPress_AcfDualCardSliderBlock {
    attributes {
      className
    }
    dualCardSlider {
      bgColor
      sliders {
        title
        text
        author
        buttons {
          text
          link {
            target
            url
          }
        }
        img {
          sourceUrl(size: MEDIUM_LARGE)
        }
      }
    }
  }
`

export const AcfDualCardSlider = ({ data }) => {
  const dualCardSlider = data ? data.dualCardSlider : false

  return (
    <Section className={`${dualCardSlider.bgColor}`}>
      <Container>
        <CardSliderDuo values={dualCardSlider.sliders} />
      </Container>
    </Section>
  )
}

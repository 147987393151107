import React, { useState } from "react"
import { graphql } from "gatsby"
import moment from "moment"

import { Section, Container } from "../../anti/grid/grid"
import { HeadingLink } from "../../anti/type/type"
import { Button } from "../../anti/buttons/buttons"
import { CardSlider } from "../../anti/card-slider/card-slider"
import { Card } from "../../anti/card/card"

export const acfEventSliderBlock = graphql`
  fragment AcfEventSliderBlock on WordPress_AcfEventSliderBlock {
    attributes {
      className
    }
    eventSlider {
      bgColor
      title
      link {
        text
        url
      }
      events {
        title
        text
        img {
          sourceUrl(size: MEDIUM)
        }
        dateEnd
        url
      }
    }
  }
`

export const AcfEventSlider = ({ data, tabList }) => {
  const [board, setBoard] = useState("news")
  const eventSlider = data.eventSlider ? data.eventSlider : false

  const handleBoard = arg => {
    setBoard(arg)
  }

  return (
    <Section className={`${eventSlider.bgColor}`}>
      <Container>
        <HeadingLink>
          <h2 className="h3">{eventSlider.title}</h2>
          <Button
            variant="link"
            link={eventSlider.link.url}
            target={eventSlider.link.target}
          >
            {eventSlider.link.text}
          </Button>
        </HeadingLink>
        <Section className="pb-0">
          <Container>
            <div className="whats-on-tabs">
              <ul>
                {tabList && tabList.forEach(list => {
                  <li
                    className={board == list && "active"}
                    onClick={() => handleBoard(list)}
                  >
                    <div>
                      {list}
                    </div>
                  </li>
                })}
              </ul>
            </div>
          </Container>
        </Section>
        {data.attributes.classname == board && (
          <CardSlider
            showInitial={4}
            showLgDown={3}
            visibleInitial={false}
            visibleLgDown={false}
            arrowsInitial={false}
            arrowsLgDown={false}
          >
            {eventSlider &&
              eventSlider.events.map((item, i) => {
                const splitDate = item.dateEnd.split("/")
                const now = moment().format("YYYY-MM-DD")
                const formatDate = [splitDate[2], splitDate[1], splitDate[0]]
                const isBefore = moment(now).isBefore(formatDate.join("-"))
                return (
                  <Card
                    variant="boxless"
                    title={item.title}
                    className={isBefore ? "" : "past-event"}
                    titleClassName="h6"
                    text={item.text}
                    img={item.img.sourceUrl}
                    imgHeight="h-ratio-1-1"
                    link={item.url}
                    target={item.target}
                    key={i}
                  />
                )
              })}
          </CardSlider>
        )}
      </Container>
    </Section>
  )
}

import React from "react"
import { graphql } from "gatsby"
import YouTube from "react-youtube"

import { Cover } from "../../anti/cover/cover"

export const CoreCoverBlock = graphql`
  fragment CoreCoverBlock on WordPress_CoreCoverBlock {
    name
    attributes {
      ... on WordPress_CoreCoverBlockAttributes {
        url
      }
    }
    innerBlocks {
      ... on WordPress_CoreHeadingBlock {
        name
        attributes {
          ... on WordPress_CoreHeadingBlockAttributes {
            content
          }
        }
      }
      ... on WordPress_CoreParagraphBlock {
        name
        attributes {
          ... on WordPress_CoreParagraphBlockAttributes {
            content
          }
        }
      }
    }
  }
`

export const CoreCover = ({ data }) => {
  const block = data ? data : false
  return (
    // block.innerBlocks.length > 0 && (
    //   block.innerBlocks.map((value, i)=>{
    //     switch(value.name) {
    //       case "core-embed/youtube":
    //         return (
    //           <div className="video-background cover-full-top">
    //           {/* prettier-ignore */}
    //           <YouTube videoId={value.attributes.url} audio={false}/>
    //         </div>
    //         )

    //       default:
    //         return <div key={`${block.name}${i}`}>Default: {block.name}</div>
    //     }
    //   })
    <Cover
      variant="basic"
      theme="dark"
      img={block.attributes && block.attributes.url}
      imgHeight="h-400px h-md-500px w-100"
      imgOverlay="40"
      className="cover cover-full-top"
      type={
        block.innerBlocks &&
        block.innerBlocks.find(x => x.name === "core-embed/youtube") &&
        block.innerBlocks.find(x => x.name === "core-embed/youtube")
      }
      videoSrc={
        block
          ? block.innerBlocks.find(x => x.name === "core-embed/youtube")
            ? block.innerBlocks.find(x => x.name === "core-embed/youtube")
                .attributes.url
            : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/04/Sinar-Mas-Land.-Building-For-a-Better.mp4"
          : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/04/Sinar-Mas-Land.-Building-For-a-Better.mp4"
      }
      imgOverlay="30"
      imgHeight="h-500px h-md-600px w-100"
      label={
        (block.innerBlocks &&
          block.innerBlocks.find(x => x.name === "core/paragraph") &&
          block.innerBlocks.find(x => x.name === "core/paragraph").attributes
            .content) ||
        ""
      }
      title={
        (block.innerBlocks &&
          block.innerBlocks.find(x => x.name === "core/heading") &&
          block.innerBlocks.find(x => x.name === "core/heading").attributes
            .content) ||
        ""
      }
      className="cover-full cover-main"
    />
  )
}

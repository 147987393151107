import React, { useState } from "react"
import { nanoid } from "nanoid"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { Section, Container } from "../../anti/grid/grid"
import { Button } from "../../anti/buttons/buttons"
import { CoverCard } from "../../anti/cover/cover"

export const AcfCoverCard = ({ data }) => {
  const block = data.coverCard ? data.coverCard : false
  return (
    <Section
      className={`cover-card-section ${block.bgColor} ${
        data.attributes && data.attributes.className
          ? data.attributes.className
          : ""
      }`}
    >
      <Container className="mw-lg text-md-center">
        <h2 className="h2 cover-card-title mb-4">
          <ReactMarkdown source={block.title} escapeHtml={false} />
        </h2>
        <CoverCard theme="dark" className="cover-card mb-4" data={block} />
        <div class="links">
          {block.buttons.map((button, i) => {
            return (
              <Button
                variant="secondary"
                target={button.link.target}
                link={button.link.url}
                className="btn-block-down-md"
                key={`${i}`}
              >
                {button.text}
              </Button>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}
